import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "shared-web-react/dist/widgets/floating-ui/tooltip";
import {
  useCanInitiateThreadQuery,
  useNewThreadWithSlugMutation,
} from "shared/dist/__generated__/components";
import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "./spinner-button";
import { SpinnerButtonProps } from "shared-web-react/dist/widgets/spinner-button";
import { allRoutes } from "../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faSend } from "@fortawesome/pro-solid-svg-icons";

// import { useIsMyPage } from "../screens/bio/bio-settings";

export function useAddThread() {
  const [addThread, addThreadResult] = useNewThreadWithSlugMutation();
  // Were relaxing the gating so anyoen can initiate a thread to anyone. it just gets
  // dropped into other if the arent connected
  // const canInitiate = true; //  !!data?.l_can_initiate_thread;
  const navigate = useNavigate();
  const sendMessage = React.useCallback(
    async (slug?: string | null) => {
      if (!slug) return;
      const result = await addThread({ variables: { slug: slug ?? "" } });
      if (result.data?.lm_get_or_create_dm_thread_by_slug?.__typename === "L_Thread_Error") {
        console.log("error", result.data?.lm_get_or_create_dm_thread_by_slug);
        return;
      }
      const thread_id = result.data?.lm_get_or_create_dm_thread_by_slug?.thread_id;
      if (!thread_id) {
        console.error("no thread id: ", result?.data);
        return;
      }
      navigate(allRoutes.MESSAGING.LIST.THREAD.buildPath({ thread_id }));
    },
    [addThread, addThreadResult]
  );
  return [
    async (slug?: string | null) => {
      return sendMessage(slug);
    },
    addThreadResult,
  ] as const;
}

export function useAddThreadButtonProps(slug?: null | string): SpinnerButtonProps {
  const { loading, data } = useCanInitiateThreadQuery({
    skip: !slug,
    variables: { slug: slug! },
  });
  // const [addThread, addThreadResult] = useNewThreadWithSlugMutation();
  // Were relaxing the gating so anyoen can initiate a thread to anyone. it just gets
  // dropped into other if the arent connected
  const canInitiate = (slug && slug === "candidteam") || !!data?.l_can_initiate_thread;
  // const { isMyPage } = useIsMyPage();
  const [sendMessage, addThreadResult] = useAddThread();

  const disabledAll = !canInitiate || !slug || addThreadResult.loading; // || !canInitiate;
  const loadingAll = loading || addThreadResult.loading;

  return React.useMemo(
    () => ({
      disabled: disabledAll,
      loading: loadingAll,
      spinnerClassName: "bottom-0",
      className: clsx("AddThreadButton relative", disabledAll && !slug && "opacity-50"),
      onClick: () => sendMessage(slug),
    }),
    [slug, sendMessage, disabledAll, loadingAll]
  );
}

export function AddThreadButton({
  slug: slugFromProps,
  disabled: disabledProp,
  children,
}: React.PropsWithChildren<{
  disabled?: boolean;
  slug?: string;
  // buttonContentOverride?: React.JSX.Element;
}>): React.JSX.Element {
  const { slug: slugFromParams } = useParams<{ slug: string }>();
  const slug = slugFromProps ?? slugFromParams;
  const { disabled, ...props } = useAddThreadButtonProps(slug);
  const canInitiate = disabled === false;
  const tip = canInitiate ? null : "Messaging blocked since you aren't connected";
  return (
    <Tooltip>
      <TooltipTrigger>
        <SpinnerButton disabled={disabled || disabledProp} {...props} noAutoCenterFlex>
          {children ? children : <FontAwesomeIcon icon={faSend} fixedWidth />}
        </SpinnerButton>
      </TooltipTrigger>
      {tip && <TooltipContent>{tip}</TooltipContent>}
    </Tooltip>
  );
}
